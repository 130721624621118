import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser'


function EmailForm() {
  const [status,setStatus]=useState('')
  const form = useRef();
 
 const sendEmail = (e) => {
   e.preventDefault(); // prevents the page from reloading when you hit “Send”
 
   emailjs.sendForm('gmail', 'portfolio', form.current, 'r7xu3C9Qs5NNC6q0P')
     .then((result) => {
         // show the user a success message
         setStatus("Your email has been successful")
     }, (error) => {
         setStatus("there was an error :" + error)
     });
 };
 
 return (

   <form ref={form}  className='contact-form' onSubmit={sendEmail}>
<p>Please send me an email if you are interested in my work or want to know more.</p>

     
     <input 
            type="text" 
            name="first-name" 
            placeholder='first name'
            title="Please enter your first name"
     />
     <input 
            type="text" 
            name="last-name" 
            placeholder='last name'
            title="Please enter your last name"
     />
     <input 
            type="email" 
            name="email" 
            placeholder='Email address'
      />
     
     <textarea name="message" placeholder='Your message'/>
     {status}
     <input type="submit" value="Send" />
   </form>
 );
}

export default EmailForm;
